import React from 'react';
import { map, sortBy, get } from 'lodash';
import Image from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Router } from '@reach/router';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import { Grid } from '@material-ui/core';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Container,
} from '@material-ui/core';
import ProductTemplate from '../templates/products';
import ProductsHeader from '../components/ProductsHeader';
import SEOHelmet from '../components/SEOHelmet';

const useStyle = makeStyles((theme) => ({
  cat: {
    '&:nth-child(odd)': {
      // backgroundColor: theme.palette.background.default,

      '& .columns': {
        flexDirection: 'row-reverse',
      },
    },

    [theme.breakpoints.down('md')]: {
      '& .columns': {
        flexDirection: 'row',
      },
    },
  },
  photo: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 375,
    },

    '& > div[aria-hidden="true"]': {
      display: 'none',
    },
  },
  content: {
    padding: `${theme.spacing(8)}`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(4)}`,
    },
  },
}));

const FeaturedProducts = () => {
  const cls = useStyle();
  const data = useStaticQuery(graphql`
    {
      contentfulPage(
        contentful_id: { eq: "20WuUnkbOjdsRLeX1rYe73" }
      ) {
        banner {
          title
          leadIn {
            raw
          }
        }
        seo {
          title
          metaDescription {
            metaDescription
          }
        }
      }

      allContentfulProductBucket(
        filter: { type: { eq: "Category" } }
      ) {
        nodes {
          name
          descriptionRt {
            raw
          }
          photo {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          slug
          sort
        }
      }
    }
  `);

  const page = data?.contentfulPage;
  const d = data?.allContentfulProductBucket?.nodes;

  return (
    <ProductsHeader
      description={get(page, 'banner.leadIn.raw')}
      title={get(page, 'banner.title')}
    >
      <SEOHelmet
        title={get(page, 'seo.title')}
        description={get(
          page,
          'seo.metaDescription.metaDescription',
        )}
      />
      {map(sortBy(d, 'sort'), (node, idx) => (
        <Box
          component="article"
          className={cls.cat}
          key={idx}
        >
          <Grid className="columns" container>
            <Grid item md={6} xs={12}>
              <Image
                {...node.photo}
                className={cls.photo}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Box className={cls.content}>
                <Container maxWidth="sm">
                  <Typography
                    variant="h2"
                    component="h2"
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    {node.name}
                  </Typography>
                  {node.descriptionRt?.raw && (
                    <Box mt={1} mb={2}>
                      <RichText
                        json={JSON.parse(
                          node.descriptionRt.raw,
                        )}
                      />
                    </Box>
                  )}
                  <Box mt={1}>
                    <Button
                      color="primary"
                      variant="outlined"
                      component={Link}
                      to={`/product-searches/${node.slug}`}
                    >
                      See products
                    </Button>
                  </Box>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </ProductsHeader>
  );
};

const Products = () => (
  <Router basepath="/products">
    <ProductTemplate path=":id" />
    <FeaturedProducts path="/" />
  </Router>
);

export default Products;

/* export async function getServerData(req) {
  return fetchProducts('productSlug')(req);
}
 */
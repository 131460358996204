import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link, useParams } from '@reach/router';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Redirect from 'gatsby-theme-q3/src/components/Redirect';
import ProductInformation from 'gatsby-theme-gentek/src/components/ProductInformation';
import ProductPricing from 'gatsby-theme-gentek/src/components/ProductPricing';
import ProductStock from 'gatsby-theme-gentek/src/components/ProductStock';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Configurator3cx from 'gatsby-theme-gentek/src/components/Configurator3cx';
import { CartContext } from 'q3-ui-cart';
import Alert from '@material-ui/lab/Alert';
import fetchProductsNoSSR from '../../integration/fetchProductsNoSSR';
import ProductTaxonomy from '../components/ProductTaxonomy';
import ProductBuyNow from '../containers/ProductBuyNow';
import { setTaxonomyLinks } from '../utils';
import ProductPhoto from '../components/ProductPhoto';
import useRecommendedProducts from '../useRecommendedProducts';
import {
  getPaymentOption,
  getOrderId,
  setOrderId,
} from '../containers/utils';
import {
  makeProductTitle,
  makeSkuBiline,
} from '../components/productCard';
import ProductPricingWrapper from '../containers/ProductPricingWrapper';
import SEOHelmet from '../components/SEOHelmet';
import { ProductsRecommendedSlider } from '../containers/ProductsRecommended';
import { execSlugify } from '../utils';

const notNull = (v) =>
  v !== null && v !== 'null' ? v : '';

const Product3cx = () => {
  const cart = React.useContext(CartContext);
  const [order, setOrder] = React.useState(getOrderId());
  const [success, setSuccess] = React.useState();

  return (
    <ProductPricingWrapper>
      {success ? (
        <Alert severity="success">
          Your license has successfully been added to the
          cart. Remember, only one 3CX license may be
          purchased/modified per order.
        </Alert>
      ) : (
        <Configurator3cx
          paymentOption={notNull(getPaymentOption())}
          order={notNull(order)}
          onDone={({ data }) => {
            setOrderId(data.order.id);
            setOrder(data.order.id);
            setSuccess(true);
            return cart.poll();
          }}
        />
      )}
    </ProductPricingWrapper>
  );
};

const ProductsTemplate = ({ location }) => {
  const paramInfo = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [data, setData] = useState({});

  useEffect(async () => {
    setLoading(true);
    const p = await fetchProductsNoSSR('productSlug')({
      paramInfo,
      location,
    });
    const d = setTaxonomyLinks(p.data);
    setProduct(p.data);
    setData(d);
    setLoading(false);
  }, [location]);

  const recommended = useRecommendedProducts(data);

  const genSeoTitle = () => {
    const vendorName = get(product, 'vendor.name');
    const sku = get(product, 'sku');
    const base = vendorName ? `${vendorName} ${sku}` : sku;

    return String(base).concat(
      ' | ',
      get(product, 'category.name'),
    );
  };

  if (loading) {
    return (
      <>
        <LinearProgress />
        <Box sx={{ height: '50vh' }} />
      </>
    );
  }

  return (
    <Box component="main" bgcolor="background.paper">
      <Container maxWidth="xl">
        <SEOHelmet
          title={genSeoTitle()}
          description={product?.excerpt}
        />
        <Box p={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <MuiLink
              component={Link}
              color="inherit"
              to="/"
            >
              Home
            </MuiLink>
            <MuiLink
              component={Link}
              color="inherit"
              to="/products"
            >
              Products
            </MuiLink>
            <MuiLink
              component={Link}
              color="inherit"
              to={`/product-categories/${execSlugify(
                product?.category?.name,
              )}`}
            >
              {product?.category?.name}
            </MuiLink>
            <Typography color="textPrimary">
              {product.sku}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box pb={4} component="article">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <ProductPhoto {...data} defer={false} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="h1">
                  {makeProductTitle(product)}
                </Typography>
                <Typography
                  variant="body2"
                  component="h2"
                  style={{
                    fontSize: 'small',
                  }}
                >
                  <Box
                    component="span"
                    color="secondary.main"
                  >
                    {makeSkuBiline(product)}
                  </Box>
                </Typography>
                <Box
                  color="secondary.main"
                  height="2px"
                  my={2}
                  style={{
                    borderBottom: '1px solid',
                  }}
                />
                <ProductInformation
                  {...data}
                  taxonomy={
                    <Box mb={4}>
                      <ProductTaxonomy {...data} />
                    </Box>
                  }
                >
                  {!get(data, 'stock.discontinued') && (
                    <>
                      <ProductPricing
                        {...data.price}
                        discounts={data.discounts}
                      />
                      <ProductStock
                        {...data.stock}
                        {...data.inventory}
                      />
                    </>
                  )}
                </ProductInformation>
                {data.sku !== '3CX_SKU' ? (
                  <ProductPricingWrapper>
                    <ProductBuyNow {...data} />
                  </ProductPricingWrapper>
                ) : (
                  <Product3cx />
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
      <Box component="section">
        <ProductsRecommendedSlider products={recommended} />
      </Box>
    </Box>
  );
};

ProductsTemplate.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default ProductsTemplate;

import React from 'react';
import PropTypes from 'prop-types';
import { size, map, first, upperCase } from 'lodash';
import {
  Box,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Checkbox,
} from '@material-ui/core';
import { AddToCart } from 'q3-ui-cart';
import ProductStock from 'gatsby-theme-gentek/src/components/ProductStock';
import ProductPricing from 'gatsby-theme-gentek/src/components/ProductPricing';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'q3-ui-locale';
import useRecommendedProducts from '../useRecommendedProducts';

const useStyle = makeStyles(() => ({
  disabled: {
    opacity: '1 !important',
  },
}));

const ProductBuyCompanions = ({ id, companions }) => {
  const { t } = useTranslation();

  const p = useRecommendedProducts({
    suggestedSkus: companions,
  });

  const [checked, setChecked] = React.useState();

  React.useEffect(() => {
    if (size(p) === 1 && !checked) {
      setChecked(first(p)?.sku);
    }
  }, [p, checked]);

  return (
    <Box display="block" maxWidth="100%" width="100%">
      <Tabs value={0}>
        <Tab
          disableRipple
          disableFocusRipple
          disabled
          value={0}
          label={t('labels:hasCompanion')}
          classes={useStyle()}
        />
      </Tabs>
      <Box my={1}>
        <Typography>
          This product requires the purchase of an
          additional product. If your order already contains
          one of the options below, the quantity will be
          updated accordingly.
        </Typography>
      </Box>
      <Box my={2}>
        {!size(p) && <CircularProgress />}
        {map(
          p,
          ({
            sku,
            manufacturerSku,
            price,
            inventory,
            stock,
            excerpt,
          }) => {
            const isActive = checked === sku;
            return (
              <Card
                key={sku}
                component="label"
                variant="outlined"
                style={{
                  display: 'block',
                }}
              >
                <CardHeader
                  title={sku}
                  subheader={`Mansku #${manufacturerSku}`}
                  avatar={
                    <Checkbox
                      checked={isActive}
                      onClick={(e) => {
                        e.preventDefault();

                        if (!isActive) setChecked(sku);
                        else setChecked();
                      }}
                    />
                  }
                  titleTypographyProps={{
                    color: 'primary',
                    style: {
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    },
                  }}
                  subheaderTypographyProps={{
                    style: {
                      fontSize: '0.8rem',
                    },
                  }}
                />
                <CardContent style={{ paddingTop: 0 }}>
                  <Box component="small" mb={1}>
                    {excerpt}
                  </Box>
                  <ProductPricing {...price} />
                  <ProductStock {...stock} {...inventory} />
                </CardContent>
              </Card>
            );
          },
        )}
      </Box>
      <AddToCart
        product={id}
        disabled={!checked}
        AddToCartButtonProps={{
          hasCompanion: checked,
        }}
      />
    </Box>
  );
};

ProductBuyCompanions.propTypes = {
  id: PropTypes.string.isRequired,
  companions: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
};

export default ProductBuyCompanions;

import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'q3-ui-locale';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { AuthContext } from 'q3-ui-permissions';

const ProductPricingWrapper = ({ children }) => {
  const { t } = useTranslation('labels');
  const { state } = React.useContext(AuthContext);

  return state.init && !state.profile ? (
    <Button
      variant="outlined"
      size="large"
      to="/login"
      component={Link}
      color="secondary"
    >
      {t('loginForPricing')}
    </Button>
  ) : (
    children
  );
};

ProductPricingWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

ProductPricingWrapper.defaultProps = {};

export default ProductPricingWrapper;

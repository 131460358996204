import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { Form, Field } from 'q3-ui-forms/src/builders';
// eslint-disable-next-line
import { useTranslation } from 'q3-ui-locale';
// eslint-disable-next-line
import * as yup from 'yup';
import use3cxLicenses from './use3cxLicenses';

const Configurator3cxLicenseWarning = ({ license }) => {
  const { t } = useTranslation('descriptions');

  return license === 'LicenseUpgradePrices' ? (
    <Box mb={1} width="100%">
      <Alert severity="warning">
        {t('renewalUpgrade')}
      </Alert>
    </Box>
  ) : null;
};

Configurator3cxLicenseWarning.defaultProps = {
  license: null,
};

Configurator3cxLicenseWarning.propTypes = {
  license: PropTypes.string,
};

const Configurator3cx = ({
  order,
  onDone,
  paymentOption,
}) => {
  const { t } = useTranslation();

  const {
    error = false,
    re,
    warning = null,
    buyLicense,
    getLicenses,
  } = use3cxLicenses(paymentOption);

  const getLicenseFieldAttributes = (
    _,
    { values: formValues },
  ) =>
    formValues?.license !== 'LicensePrices'
      ? {
          disabled: !formValues.licenseKey,
          helperText: t(
            'descriptions:disabledUntilLicenseKey',
          ),
        }
      : {};

  const getRenewalFieldAttributes = (
    _,
    { values: formValues },
  ) => {
    const label =
      formValues?.license === 'RenewPrice'
        ? 'additionalYearRenewal'
        : 'additionalYearRenewalOptional';

    return {
      label: t(`labels:${label}`),
      helperText: t(`helpers:${label}`),
      required: label === 'additionalYearRenewal',
    };
  };

  const ifRenewingFreeLicense = (xs = {}) =>
    xs &&
    String(xs.product).includes('"Edition":"Standard"') &&
    String(xs.product).includes('"SimultaneousCalls":4') &&
    xs.license === 'RenewPrice';

  return (
    <Form
      initialValues={{
        addHosting: false,
        license: 'LicensePrices',
        order,
      }}
      onSubmit={(values) => buyLicense(values).then(onDone)}
      restart
      submitLabel="addToCart"
    >
      {(values) => (
        <>
          <Box mb={1} width="100%">
            <Alert severity="warning">
              {t('descriptions:singleQuantityOnly')}
            </Alert>
          </Box>
          <Configurator3cxLicenseWarning {...values} />
          <Collapse in={warning}>
            <Box mb={1}>
              <Alert severity="warning">{warning}</Alert>
            </Box>
          </Collapse>
          <Collapse in={error}>
            <Box mb={1}>
              <Alert severity="error">
                {t('descriptions:3cxError')}
              </Alert>
            </Box>
          </Collapse>
          <Field
            xl={12}
            lg={12}
            name="license"
            type="group"
            options={[
              { label: 'Buy', value: 'LicensePrices' },
              {
                label: 'Upgrade',
                value: 'LicenseUpgradePrices',
              },
              {
                label: 'Maintain/Renew',
                value: 'RenewPrice',
              },
            ]}
          />
          <Field
            xl={12}
            lg={12}
            name="licenseKey"
            conditional={[
              'license=LicenseUpgradePrices,RenewPrice',
            ]}
            helperText="XXXX-XXXX-XXXX-XXXX"
            type="text"
            validate={yup.string().matches(re)}
          />
          <Field
            xl={12}
            lg={12}
            preload
            suppressHelper
            name="product"
            type="select"
            listen="license"
            runOnChange={[
              'license',
              'licenseKey',
              'additionalYears',
              'addHosting',
            ]}
            override={getLicenseFieldAttributes}
            loadOptions={(v, formValues) =>
              getLicenses(formValues)
            }
          />
          <Field
            name="additionalYears"
            conditional={[
              'license=LicensePrices,RenewPrice',
            ]}
            override={getRenewalFieldAttributes}
            type="number"
            xl={12}
            lg={12}
          />
          {!ifRenewingFreeLicense(values) && (
            <Field
              label={t('labels:includeHosting')}
              helper={t('helpers:includeHosting')}
              name="addHosting"
              type="checkbox"
              variant="switch"
            />
          )}
        </>
      )}
    </Form>
  );
};

Configurator3cx.propTypes = {
  onDone: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  paymentOption: PropTypes.string.isRequired,
};

export default Configurator3cx;

import React from 'react';
import axios from 'axios';
import { get, isObject } from 'lodash';

function debounce(f, interval) {
  let timer = null;

  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(f(...args)),
        interval,
      );
    });
  };
}

const use3cxLicenses = (paymentOption) => {
  const [warning, setWarning] = React.useState();
  const [error, setError] = React.useState(false);

  const endpoint = '/3cx-configurator';
  const re = /\w{4}-\w{4}-\w{4}-\w{4}/gim;

  const headers = {
    headers: {
      'x-display-gateway': paymentOption,
    },
  };

  const cleanOptions = (options = {}) => {
    if (!isObject(options)) return {};

    const copy = { ...options };

    copy.addHosting = String(options.addHosting) === 'true';
    copy.additionalYears =
      options.additionalYears &&
      !Number.isNaN(Number(options.additionalYears))
        ? options.additionalYears
        : 0;

    copy.type = copy.license;
    return copy;
  };

  const checkForApiResponseWarning = (res) => {
    const str = get(res, 'data.warning');
    if (str) {
      setWarning(str);
    } else {
      setWarning(null);
    }
  };

  const isValidLicenseKey = (xs) =>
    xs && String(xs).match(re);

  const requiresLicenseKey = (xs) =>
    ['LicenseUpgradePrices', 'RenewPrice'].includes(xs);

  const makeEndpoint = ({ licenseKey, ...params }) => {
    if (requiresLicenseKey(params.type)) {
      if (isValidLicenseKey(licenseKey))
        Object.assign(params, {
          licenseKey,
        });
      else throw new Error('License required');
    }

    return `${endpoint}?${Object.entries(params)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
  };

  return {
    error,
    re,
    warning,

    buyLicense(options = {}) {
      // left optional so that we can reset dropdown
      // without a match
      if (!options.product) {
        const err = new Error();
        err.message =
          'You must select a license type to continue.';
        return Promise.reject(err);
      }

      return axios.post(
        endpoint,
        Object.entries(cleanOptions(options)).reduce(
          (acc, [key, value]) => {
            if (value) acc[key] = value;
            return acc;
          },
          {},
        ),
        headers,
      );
    },

    getLicenses: debounce((options = {}) => {
      try {
        return axios
          .get(makeEndpoint(cleanOptions(options)), headers)
          .then((r) => {
            const incomingQuotes = get(
              r,
              'data.quotes',
              [],
            );

            setError(false);
            checkForApiResponseWarning(r);
            return incomingQuotes;
          })
          .catch(() => {
            setError(true);
          });
      } catch (e) {
        return Promise.resolve([]);
      }
    }, 450),
  };
};

export default use3cxLicenses;

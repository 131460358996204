import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import {
  getVendorProductSlug,
  getCategoryProductSlug,
} from '../utils';

const useStyle = makeStyles((theme) => ({
  root: {
    // color: theme.palette.secondary.main,
    // textDecoration: 'underline !important',
    marginRight: theme.spacing(0.25),
    cursor: 'pointer',
  },
}));

const GatsbyLinkWrapper = ({ children, to }) => {
  const { root } = useStyle();
  return (
    <Chip
      component={GatsbyLink}
      className={root}
      label={children}
      to={to}
      color="secondary"
      size="large"
    />
  );
};

export default ({ vendor, category }) => (
  <Typography>
    {category?.name && (
      <GatsbyLinkWrapper
        to={getCategoryProductSlug(category.name)}
      >
        {category.name}
      </GatsbyLinkWrapper>
    )}
    {vendor?.name && (
      <GatsbyLinkWrapper
        to={getVendorProductSlug(vendor.name)}
        icon={COLLECTIONS.VENDORS.ICON}
      >
        {vendor.name}
      </GatsbyLinkWrapper>
    )}
  </Typography>
);

import React from 'react';
import { get, size } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import PropTypes from 'prop-types';
import { AddToCart } from 'q3-ui-cart';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from 'q3-ui-permissions';
import useResellerLogin from '../useResellerLogin';
import ProductBuyCompanions from './ProductBuyCompanions';

const Buy = ({ id, stock, type, ...rest }) => {
  const { t } = useTranslation('');
  const { canCreate } = useAuth('orders');
  const { ordersEnabled } = useResellerLogin();

  if (!get(rest, 'price.custom'))
    return (
      <Alert severity="warning">
        {t('descriptions:callForPricing')}
      </Alert>
    );

  return get(stock, 'isPurchasable') &&
    canCreate &&
    ordersEnabled ? (
    <Box my={2} display="inline-block" maxWidth="100%">
      {size(rest.companions) ? (
        <ProductBuyCompanions {...rest} id={id} />
      ) : (
        <AddToCart product={id} size="small" {...rest} />
      )}
    </Box>
  ) : (
    <Alert severity="error">
      {t('descriptions:unavailableForOrder')}
    </Alert>
  );
};

Buy.propTypes = {
  id: PropTypes.string.isRequired,
  stock: PropTypes.shape({
    isPurchasable: PropTypes.bool,
  }),
};

Buy.defaultProps = {
  stock: {
    isPurchasable: false,
  },
};

export default Buy;

import React from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { object, array } from '@lolly-labs/q3-ui-helpers';
import { OrderItemKitDescription } from './OrderItemKit';
import ProductOffers from './ProductOffers';
import FileDownload from './FileDownload';

const ProductInformation = ({
  description,
  price,
  datasheet,
  components,
  children,
  discounts,
  uploads,
  sku,
  taxonomy,
}) => {
  const { state } = useLocation();
  const [currentIndex, setIndex] = React.useState(
    state?.fromOffer ? 1 : 0,
  );

  const renderTab = (label, showElement, index) =>
    showElement ? (
      <Tab label={label} onClick={() => setIndex(index)} />
    ) : null;

  const renderComponent = (expectedIndex, Element, props) =>
    expectedIndex === currentIndex ? (
      <Element {...props} />
    ) : null;

  const rawTabs = [
    renderTab('Information', object.hasKeys(price), 0),
    renderTab('Offers', array.hasLength(discounts), 1),
    renderTab('Components', array.hasLength(components), 2),
  ];

  const tabs = rawTabs.filter(Boolean);

  React.useState(() => {
    if (!rawTabs[currentIndex]) {
      const newIndex = rawTabs.findIndex(Boolean);

      if (newIndex !== currentIndex && newIndex !== -1)
        setIndex(newIndex);
    }
  }, [tabs]);

  return (
    <>
      <Typography gutterBottom>{description}</Typography>
      <Box my={2}>
        {datasheet && String(datasheet) !== '0' ? (
          <Box mb={0.5}>
            <Link href={datasheet} target="_blank">
              <PictureAsPdfIcon /> Technical specifications
            </Link>
          </Box>
        ) : null}
        <FileDownload files={uploads} />
      </Box>
      {taxonomy}
      {tabs.length > 0 && (
        <>
          <Tabs value={currentIndex}>
            {tabs.map((el) => el)}
          </Tabs>
          <Box py={2}>
            {renderComponent(0, () => children)}
            {renderComponent(1, ProductOffers, {
              discounts,
              sku,
            })}
            {renderComponent(2, OrderItemKitDescription, {
              components,
            })}
          </Box>
        </>
      )}
    </>
  );
};

ProductInformation.propTypes = {
  description: PropTypes.string.isRequired,
  category: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
  datasheet: PropTypes.string,
  price: PropTypes.shape({
    cusom: PropTypes.number,
    volume: PropTypes.number,
    msrp: PropTypes.number,
  }),
  components: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      manufacturerSku: PropTypes.string,
      photo: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
};

ProductInformation.defaultProps = {
  datasheet: null,
  price: {},
  components: [],
  children: null,
};

export default ProductInformation;

import React from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Box from '@material-ui/core/Box';
import { capitalize } from 'lodash';

export const removeEmpty = (x) => x.replace(/\s/g, '');

export const removeExtension = (x) => {
  const splitted = x.split('.');
  splitted.pop();
  return splitted.join();
};

const FileDownload = ({ files }) =>
  !Array.isArray(files) || files.length === 0
    ? null
    : files.map(({ name, url, ...rest }) => (
        <Box mb={0.5}>
          <a aria-label="Download file" href={url} download>
            <AttachFileIcon />
            {capitalize(removeExtension(name))}
          </a>
        </Box>
      ));

FileDownload.defaultProps = {
  files: [],
};

FileDownload.propTypes = {
  files: PropTypes.array,
};

export default FileDownload;

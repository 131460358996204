import React from 'react';
import { compact, map, get, flatten, uniq } from 'lodash';
import axios from 'axios';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import globToRegex from 'glob-to-regexp';
import { compose } from 'lodash/fp';

const append = (str) => (xs) => compact([xs, str]).join('');

const mapToSuggestedSkus = (xs) => map(xs, 'suggestedSkus');

const mapToRegex = (xs) =>
  map(xs, (item) => {
    try {
      return globToRegex(item);
    } catch (e) {
      return null;
    }
  })
    .filter(Boolean)
    .map(append('i'))
    .join(',');

export const getSuggestedSkus = (data) =>
  compose(
    mapToRegex,
    uniq,
    compact,
    flatten,
    mapToSuggestedSkus,
  )([get(data, 'components', []), data].flat(5));

export default (data) => {
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    const skus = getSuggestedSkus(data);
    if (skus)
      axios
        .get(`${COLLECTIONS.PRODUCTS.ENDPOINT}?sku=${skus}`)
        .then((resp) => setProducts(resp?.data?.products));

    return () => {
      setProducts([]);
    };
  }, [data]);

  return products;
};

import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import Typography from '@material-ui/core/Typography';
import { array } from '@lolly-labs/q3-ui-helpers';
import { RebateList } from './OrderItemRebates';

const { hasLength } = array;

export default ({ discounts, sku }) => {
  const { t } = useTranslation('descriptions');

  if (!hasLength(discounts)) return null;

  return (
    <Typography component="div">
      <Typography>{t('eligibleRebates')}</Typography>
      <RebateList rebates={discounts} sku={sku} />
    </Typography>
  );
};
